import env from "#env";
import createApi from "../../../createApi";

import { QUERY_TAGS } from "#constants/query";
import {
  GetHubspotListsRequest,
  GetHubspotListsResponse,
  HubspotImportRequest,
} from "#customTypes/oauth";
import { channelImportsApi } from "#features/channel/channelImportsAPI";
import { appBaseQuery } from "#features/common/baseQuery";

export const hubspotApi = createApi({
  reducerPath: "hubspot",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/oauth/hubspot/`,
  }),
  tagTypes: [QUERY_TAGS.ChannelImports],
  endpoints: (builder) => ({
    fetchHubspotLists: builder.query<GetHubspotListsResponse, GetHubspotListsRequest>({
      query: ({ organisationId }) => ({
        url: `get-lists`,
        method: "GET",
        params: {
          organisationId,
        },
        credentials: "include",
      }),
      keepUnusedDataFor: 0,
    }),
    requestHubspotImport: builder.mutation<void, HubspotImportRequest>({
      query: (body) => ({
        url: `import`,
        method: "POST",
        body,
        credentials: "include",
      }),
      onQueryStarted: async ({ channelId }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        dispatch(
          channelImportsApi.util.invalidateTags([
            { type: QUERY_TAGS.ChannelImports, id: channelId },
          ])
        );
      },
    }),
  }),
});

export const { useFetchHubspotListsQuery, useRequestHubspotImportMutation } = hubspotApi;
