import env from "#env";
import createApi from "../../../createApi";

import { QUERY_TAGS } from "#constants/query";
import { ProcessEmailFileRequest } from "#customTypes/invite";
import { channelImportsApi } from "#features/channel/channelImportsAPI";
import { appBaseQuery } from "#features/common/baseQuery";

export const emailFilesApi = createApi({
  reducerPath: "emailFiles",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/email-files/`,
  }),
  tagTypes: [QUERY_TAGS.ChannelImports],
  endpoints: (builder) => ({
    processEmailFile: builder.mutation<void, ProcessEmailFileRequest>({
      query: (body) => ({
        url: `process`,
        method: "POST",
        body,
        credentials: "include",
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        dispatch(
          channelImportsApi.util.invalidateTags([
            { type: QUERY_TAGS.ChannelImports, id: "all" },
          ])
        );
      },
    }),
    dismissEmailFile: builder.mutation<void, string>({
      query: (emailFileId) => ({
        url: `cancel`,
        method: "POST",
        body: { emailFileId },
        credentials: "include",
      }),
      onQueryStarted: async (_emailFileId, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        dispatch(
          channelImportsApi.util.invalidateTags([
            { type: QUERY_TAGS.ChannelImports, id: "all" },
          ])
        );
      },
    }),
  }),
});

export const { useProcessEmailFileMutation, useDismissEmailFileMutation } = emailFilesApi;
